import React, { memo } from 'react';
import Link from 'next/link';

function Index() {
  return (
    <>
      <section className="page_404 align-items-center pb-120 pt-120 p-relative">
        <div className="container">
          <div className="row">
            <div className="col-sm-12  col-sm-offset-1  text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center ">404</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2">Look like you're lost</h3>

                <p>The page you are looking for not avaible!</p>
              </div>
              <div className="slider-btn">
                <Link href="/" className="btn ss-btn smoth-scroll">
                  Home <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default memo(Index);
