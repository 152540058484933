import NotFound from '@/Layout/NotFound';

function Index() {
  return (
    <>
      <NotFound />
    </>
  );
}
Index.auth = false;
Index.meta = {
  title: '247torax #1 Escort Classified Site | Call Girl in India',
  description:
    'Meet with the high-profile call girls in your city. Find the call girl in Chennai, Bangalore, Hyderabad, Jaipur, Pune, Mumbai, Udaipur. POST YOUR AD absolutely FREE!',
  keyword:
    'Call girl Chennai, Call girl in Bangalore, Hyderabad call girl, Escort service Jaipur, Call girl Pune, Call girl in Mumbai, Udaipur call girl, Escort service Jaipur',
  siteUrl: process.env.REACT_BASE_URL + '/404',
  locale: 'en_us',
  ogTitle: 'Adult Classified site in India - 247torax',
  ogType: 'website',
  ogUrl: process.env.REACT_BASE_URL + '/404',
  siteName: '247torax',
  ogDescription:
    'Meet with the high-profile call girls in your city. Find the call girl in Chennai, Bangalore, Hyderabad, Jaipur, Pune, Mumbai, Udaipur. POST YOUR AD absolutely FREE!',
};
export default Index;
